// Our people
jQuery(document).ready(function ($) {
    $('.people-front-info').click(function () {
        $(".people_back_info_" + $(this).data('key')).show();
    });
    $('.people-back-info').click(function () {
        $(".people_back_info_" + $(this).data('key')).hide();
    });

    $('.noti-icon').html('');

    // Back to top button
    window.onscroll = function () {
        if (window.scrollY >= 1000) {
            $('.back-to-top').show();
        } else {
            $('.back-to-top').hide();
        }
    };
    $('.back-to-top-icon').on({
        mouseenter: function () {
            $('.back-to-top-text').show(500);
        },
        mouseleave: function () {
            $('.back-to-top-text').hide(500);
        }
    });

    // For single article
    setTimeout(function () {
        var pageBannerSection = $('#page_banner_article').html();
        if (pageBannerSection.length > 0) {
            $('#page_banner_top_article').addClass('page-banner');
            $('#page_banner_top_article').html(pageBannerSection);
        }
    }, 1000);

    var itemMenuSideBar = $('.item-menu');
    itemMenuSideBar.on('click', function () {
        var collapse_sub_item = $(this).find('.collapse-sub-item');
        var icon_dropdown_menu = $(this).find('.icon-dropdown-menu');
        if (collapse_sub_item.is(":hidden")) {
            collapse_sub_item.toggle(100, "linear");
            $(this).addClass('active');
            icon_dropdown_menu.addClass('click-icon-dropdown-menu');
        }
        else {
            collapse_sub_item.hide(100);
            $(this).removeClass('active');
            icon_dropdown_menu.removeClass('click-icon-dropdown-menu');
        }
    });

    function changeWidthTableOfArticle() {
        var table_of_article = $('.table-of-article');
        var content_article_section = $('.content-article-section');
        if (window.outerWidth < 1024) {
            table_of_article.css('max-width', (window.outerWidth - 25) + 'px');
        } else {
            table_of_article.css('max-width', (content_article_section.width() - 5) + 'px');
            $('.vdx-article-content').find('img').css('max-width', (content_article_section.width()) + 'px');
        }
    }

    changeWidthTableOfArticle();
    $(window).resize(function () {
        changeWidthTableOfArticle();
    });
});