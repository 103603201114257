$('.slick-slider-imaging-section').slick({
    arrows: false,
    swipe: true,
    swipeToSlide: true,

    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    centerMode: true,

    autoplay: true,
    autoplaySpeed: 4000,
    infinite: true,
    lazyLoad: 'ondemand',

    pauseOnDotsHover: true,
    focusOnSelect: true,
    centerPadding: '18%',
    responsive: [
        {
            breakpoint: 768,
            settings: {
                centerPadding: '0%',
            }
        }
    ]
});

$('.slick-slider-partner-section').slick({
    arrows: false,
    swipe: true,
    swipeToSlide: true,

    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    centerMode: true,

    autoplay: true,
    autoplaySpeed: 4000,
    infinite: true,
    lazyLoad: 'ondemand',
    focusOnSelect: true,
    pauseOnDotsHover: true,
    centerPadding: '37.5%',
    responsive: [
        {
            breakpoint: 768,
            settings: {
                centerPadding: '5%',
            }
        }
    ]
});

$('.slick-slider-testimonials-section').slick({
    arrows: false,
    swipe: true,
    swipeToSlide: true,

    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    centerMode: true,

    autoplay: true,
    autoplaySpeed: 4000,
    infinite: true,
    lazyLoad: 'ondemand',
    focusOnSelect: true,
    pauseOnDotsHover: true,
    responsive: [
        {
            breakpoint: 768,
            settings: {
                centerPadding: '0%',
            }
        }
    ]
});

$('.slick-slider-service-section').slick({
    arrows: false,
    swipe: true,
    swipeToSlide: true,

    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    centerMode: true,

    autoplay: true,
    autoplaySpeed: 4000,
    infinite: true,
    lazyLoad: 'ondemand',
    focusOnSelect: true,
    pauseOnDotsHover: true,
    centerPadding: '0%',
});

